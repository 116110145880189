import { useRouter } from "next/router";
import { rgba } from 'polished';
import { useCallback } from 'react';
import ButtonLink from "src/components/buttonlink/ButtonLink";
import LocationResultList from 'src/components/locationresultlist/LocationResultList';
import ResultPosterRow from 'src/components/search/ResultPosterRow';
import SuspenseFallback from 'src/components/suspensefallback/SuspenseFallback';
import theme from 'src/components/themes/DefaultTheme';
import { useAzLocationSearch, useAzSearch } from 'src/lib/client/useSearch';
import styled from "src/lib/styles/css";


//#region [Props]
type SearcResultProps = {
    searchExpression?: string;
    showSearchResult: boolean;
    azureSearchEnabled?: boolean;
    azShowScore?: boolean;
    customSortNum?: number | string;
};
//#endregion
const noOfMoviesToShow = 6;

//#region [Component]
export default function SearchResult({ searchExpression, showSearchResult, azureSearchEnabled = false, azShowScore = false, customSortNum }: SearcResultProps) {
    const { searchLoading: locationSearchLoading, searchResult: locationResult } = useAzLocationSearch(searchExpression, { active: !!searchExpression?.trim() });
    const { searchLoading: azSearchLoading, searchResult: azSearchResult } = useAzSearch(searchExpression, { quickSearch: true, fuzzyWeight: 0, maxNumItems: noOfMoviesToShow, active: !!searchExpression?.trim(), customSortNum });
    const router = useRouter();

    let fullSearchUrl = "/sok";
    if (router.pathname.indexOf("/experimental") !== -1) {
        fullSearchUrl = router.pathname;
    }
    fullSearchUrl = `${fullSearchUrl}?q=${encodeURIComponent(searchExpression ?? "")}`;
    const hasSearchExpression = !!searchExpression?.trim();

    const _trackLocSearch = useCallback((locName: string) => trackSearch(locName, "Kinosted", searchExpression!), [searchExpression]);
    const _trackSearch = useCallback((id: string | number, title: string) => trackSearch(id, title, searchExpression!), [searchExpression]);

    const searchLoading = azSearchLoading || locationSearchLoading;

    return <SSearchResult>
        {showSearchResult && hasSearchExpression && <>
            {searchLoading && <SSearchResultsEmpty>
                <SuspenseFallback text='Henter resultater...' />
            </SSearchResultsEmpty>}

            {!searchLoading && ((azSearchResult?.results?.length ?? 0) === 0) && (locationResult?.results?.length ?? 0) === 0 && <SSearchResultsEmpty>
                Ingen treff
            </SSearchResultsEmpty>}

            {!searchLoading && ((locationResult?.results?.length ?? 0) > 0 || (azSearchResult?.results?.length ?? 0) > 0) && <SSearchResultsList>
                <LocationResultList locationList={locationResult?.results} clickTracker={_trackLocSearch} />
                {(azSearchResult?.results?.length ?? 0) > 0 && <ResultPosterRow searchResult={azSearchResult?.results!} maxResults={noOfMoviesToShow} clickTracker={_trackSearch} azureSearchEnabled azShowScore={azShowScore} />}
            </SSearchResultsList>}
            {!searchLoading && <>
                {(azSearchResult?.totalResults ?? 0) > noOfMoviesToShow ? <SButtonLinkContainer><SButtonLink href={fullSearchUrl} inverse>Se flere treff</SButtonLink></SButtonLinkContainer> : <SButtonLinkContainer><SButtonLink href={fullSearchUrl} inverse>Utvid søket</SButtonLink></SButtonLinkContainer>}
            </>}

        </>}
    </SSearchResult>;
}
//#endregion

//#region [Other] trackSearch
function trackSearch(id: string | number, title: string, searchExpression: string) {
    if (typeof window !== "undefined" && searchExpression?.trim().length > 0) {
        window!.dataLayer?.push({
            'event': 'fw_Quicksearch',
            'eventCategory': 'Hurtigsøk',
            'eventAction': `${title} | ${id}`,
            'eventLabel': searchExpression
        });
    }
}
//#endregion

//#region [Styles]
const SSearchResult = styled.div`
	position: absolute;
	top: 55px;
	left: 0;
	right: 0;
	//transform: scaleY(1);
	//transform-origin: top;
	background-color: ${rgba(theme.mainColor, theme.generalAlpha)};
	color: ${theme.textColor};
	z-index: 101;
	overflow: hidden;
	transition: transform 0.2s ease-in-out;
`;

const SSearchResultsList = styled.div`
	width: 100%;
	max-width: ${theme.maxContentWidth}px;
	padding: 0 20px;
	margin: 0 auto;

`;

const SSearchResultsEmpty = styled.div`
	min-height: 50px;
	padding: 20px;
	max-width: 650px;
	margin: auto;
	text-align: center;
`;

const SButtonLinkContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: ${theme.maxContentPadding};
`;

const SButtonLink = styled(ButtonLink)`
    margin: 0 auto;
`;

//#endregion