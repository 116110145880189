import ResultLandscapeImage from 'src/components/search/ResultLandscapeImage';
import ResultPoster, { AzResultPoster } from 'src/components/search/ResultPoster';
import { WatchableContent } from 'src/lib/movieinfo/movieinfotypes';
import { ISearchResult } from "src/lib/types/search";
//#region [Styles]

//#endregion

//#region [Props]
type SearchContentItemProps = {
    content: WatchableContent | ISearchResult;
    forceStreaming?: boolean;
    className?: string;
    clickTracker?: (id: string | number, title: string) => void;
    azShowScore?: boolean;
    azureSearchEnabled?: boolean;
};
//#endregion

//#region [Component]
export default function SearchContentItem({ content, className, clickTracker, forceStreaming = false, azShowScore = false, azureSearchEnabled = false }: SearchContentItemProps) {
    const useAzureSearch = process.env.NEXT_PUBLIC_USE_AZURE_SEARCH === "true" || azureSearchEnabled;
    const displayFormat = useAzureSearch ? "poster" : determineImagePresentation(content as WatchableContent);

    let key: string | number;
    if (useAzureSearch) {
        const item = content as ISearchResult;
        key = (item.document.edi ?? item.document.streamingId)!;
    } else {
        const item = content as WatchableContent;
        key = item.streamingContentId ?? item.movieId;
    }

    if (displayFormat === "landscape") {
        return (<ResultLandscapeImage key={key}
            forceStreaming={forceStreaming}
            className={className}
            data={(content as WatchableContent)}
            clickTracker={clickTracker}
        />);
    }

    return (useAzureSearch ? <AzResultPoster key={key}
        forceStreaming={forceStreaming}
        className={className}
        data={content as ISearchResult}
        clickTracker={clickTracker}
        showScore={azShowScore}
    /> : <ResultPoster key={key}
        forceStreaming={forceStreaming}
        className={className}
        data={content as WatchableContent}
        clickTracker={clickTracker}
    />);
}
//#endregion

//#region [Other]
function determineImagePresentation(content: WatchableContent): "landscape" | "poster" {
    if (content) {
        if (content.sanityImagePosterUrl || (content.imagesPosterStreaming?.length ?? 0) > 0) return "poster";
        if (content.sanityImageWideUrl || (content.imagesLandscapeStreaming?.length ?? 0) > 0 || content.imageLandscapeStreaming) return "landscape";
    }
    return "poster";
}
//#endregion