import withWatchlist from 'src/components/filmwebid/withWatchlist';
import SearchContentItem from 'src/components/search/SearchContentItem';
import theme from 'src/components/themes/DefaultTheme';
import { WatchableContent } from 'src/lib/movieinfo/movieinfotypes';
import styled, { css } from "src/lib/styles/css";
import { ISearchResult } from "src/lib/types/search";

//#region [Styles]
const gap = 30;

type SPosterRowProps = {
    $isDisabled?: boolean;
    $includeMargins?: boolean;
}

const SPosterRow = styled.div<SPosterRowProps>`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin-bottom: 25px;
	margin-top: 20px;
	justify-content: space-between;

	${props => props.$isDisabled && css`
		opacity: 0.5;
		transition: opacity 0.5s;
	`}

	.RowItem {
		@media ${theme.mq.mobile}  {
			//@media (orientation: portrait) {
			width: calc(50% - ${gap / 2}px);
			margin-bottom: ${gap};
		}

		@media ${`(orientation: portrait) and ${theme.mq.desktop}`} {
			//@media (orientation: portrait) and (min-width: 768px) {
			width: calc(33.3% -  ${gap / 2}px);
		}

		@media ${theme.mq.desktop} {
			//@media (orientation: landscape) {
			width: calc(100% / var(--max-results) - ${gap}px);
		}
	}

	${props => props.$includeMargins && css`
		@media ${theme.mq.desktop} {
			justify-content: center;

			.RowItem {
				margin: 0 ${gap / 2}px;
			}
		}
	`}

`;

const WLSearchContentItem = withWatchlist(SearchContentItem, "content");

const SWLSearchContentItem = styled(WLSearchContentItem)`
	width: 100%;
`;
//#endregion

//#region [Props]
type ResultPosterRowProps = {
    maxResults: number;
    searchResult: WatchableContent[] | ISearchResult[];

    className?: string;
    disabled?: boolean;
    clickTracker?: (id: string | number, title: string) => void;
    forceStreaming?: boolean; // use streaming link if available, even if isCinemaRelevant = true
    azureSearchEnabled?: boolean;
    azShowScore?: boolean;
};
//#endregion

//#region [Component]


export default function ResultPosterRow({ searchResult, maxResults, className, disabled = false, clickTracker, forceStreaming = false, azureSearchEnabled = false, azShowScore = false }: ResultPosterRowProps) {
    return <SPosterRow className={className} $includeMargins={searchResult.length < maxResults} $isDisabled={disabled} style={{ "--max-results": maxResults } as React.CSSProperties}>
        {searchResult.slice(0, maxResults).map(sr => (
            <SWLSearchContentItem
                wlcClassName='RowItem'
                key={isSearchResult(sr) ? (sr.document.edi ?? sr.document.streamingId) : (sr.streamingContentId ?? sr.movieId)}
                content={sr}
                clickTracker={clickTracker}
                forceStreaming={forceStreaming}
                azureSearchEnabled={azureSearchEnabled}
                azShowScore={azShowScore}
            />
        ))}
    </SPosterRow>;
}
//#endregion
function isSearchResult(sr: WatchableContent | ISearchResult): sr is ISearchResult {
    return sr.__typename === "ISearchResult"
}