import { gql } from "graphql-request";
import { useState } from "react";
import SelectorWrapper from "src/components/selectorwrapper/SelectorWrapper";
import SearchSvg from "src/components/svg/SearchSvg";
import theme from "src/components/themes/DefaultTheme";
import { useAzLocationSearch } from "src/lib/client/useSearch";
import { useLocationContext } from "src/lib/contexts/LocationContext";
import { Location } from "src/lib/movieinfo/movieinfotypes";
import styled from 'src/lib/styles/css';

//#region [Props]
type LocationSelectorProps = {
    className?: string;
};
//#endregion

const hotlinkLocations: Location[] = [
    { name: "Oslo" },
    { name: "Oslo og omegn" },
    { name: "Bergen" },
    { name: "Trondheim" },
    { name: "Stavanger" },
    { name: "Sandnes" },
    { name: "Sandvika" },
    { name: "Lillestrøm" },
    { name: "Ski" },
    { name: "Drammen" },
    { name: "Kristiansand S" }
];

//#region [Component]
export default function LocationSelector({ className }: LocationSelectorProps) {
    const locationContext = useLocationContext();
    const [searchText, setSearchText] = useState("");

    const { searchResult } = useAzLocationSearch(searchText, { active: !!searchText, inputDelay: 400 });

    return <SelectorWrapper onClose={() => locationContext.setShowLocationDialog(false)} heading="Velg sted">
        <SLocationSelector>
            <SSearchField>
                <SSearchInput type="text" placeholder="Søk sted" onChange={(e) => setSearchText(e.target.value)} ref={input => { if (input) input.focus() }} />
                <SIconPlaceholder>
                    <SSearchSvg />
                </SIconPlaceholder>
            </SSearchField>
            <SResultArea>
                {(searchResult?.results?.map(r => ({ name: r })) ?? hotlinkLocations).slice(0, hotlinkLocations.length).map((loc, index) => (
                    <SResultItem key={index} onClick={() => {
                        locationContext.setLocation(loc!.name!);
                        locationContext.setShowLocationDialog(false);
                    }}>{loc!.name}</SResultItem>
                ))}
            </SResultArea>
        </SLocationSelector>
    </SelectorWrapper>;
}
//#endregion

//#region [Styles]
const SLocationSelector = styled.div`
	min-height: 50cqi;
`;

const SSearchField = styled.div`
	display: flex;
	justify-content: center;
`;

const SSearchInput = styled.input`
	background-color: var(--TEXTCOLOR_OVERRIDE, var(--textcolor));
	border: none;
	height: 39px;
	font-size: 16px;
	border-radius: 20px 0 0 20px;
	padding: 5px 5px 5px 15px;
	width: 70%;
	color: var(--BACKGROUNDCOLOR_OVERRIDE, black) !important;

	@media ${theme.mq.desktop} {
		//@media (min-device-width: 960px) {
		background-color: var(--TEXTCOLOR_OVERRIDE, var(--buttontextcolor));

		width: 75%;
	}
`;

const SIconPlaceholder = styled.div`
	height: 39px;
	width: 32px;
	padding-top: 4px;
	background-color: var(--TEXTCOLOR_OVERRIDE, var(--buttontextcolor));
	border-radius: 0 20px 20px 0;
`;

const SSearchSvg = styled(SearchSvg)`
	height: 20px;
	margin-top: 5px;

	path {
		fill: var(--BACKGROUNDCOLOR_OVERRIDE, var(--buttoncolor));
	}
`;

const SResultArea = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
`;

const SResultItem = styled.button`
	padding: 10px 15px;
	cursor: pointer;
	font-size: 20px;
	font-weight: bold;

	background: transparent;
	color: var(--TEXTCOLOR_OVERRIDE, var(--textcolor));
	border: none;
`;
//#endregion