import FilmwebLink from 'src/components/filmweblink/FilmwebLink';
import { CmsImageForUrl } from "src/components/images/CmsImageForUrl";
import ExternalImageForUrl from "src/components/images/ExternalImageForUrl";
import Image from 'src/components/images/Image';
import CSSPoster from 'src/components/posterrow/CSSPoster';
import { SRowProps } from 'src/components/search/SRowProps';
import theme from 'src/components/themes/DefaultTheme';
import { getContentUrl } from 'src/lib/movieinfo/getContentUrl';
import { type ImageVersion, type WatchableContent } from 'src/lib/movieinfo/movieinfotypes';
import styled, { css } from "src/lib/styles/css";


//#region [Props]
type ResultLandscapeImageProps = {
	data: WatchableContent;

	forceStreaming?: boolean;
	mobileLayout?: "row" | "column";
	clickTracker?: (id: string | number, title: string) => void;
	className?: string;
};
//#endregion

//#region [Component]
export default function ResultLandscapeImage({ data, className, clickTracker, mobileLayout = "column", forceStreaming = false }: ResultLandscapeImageProps) {
	const hasSanityImage = !!data.sanityImageWideUrl;
	const hasStreamingImageArray = !hasSanityImage && (data.imagesLandscapeStreaming?.length ?? 0) > 0;
	const hasStreamingImageUrl = !hasStreamingImageArray && data.imageLandscapeStreaming;
	const hasImage = hasSanityImage || hasStreamingImageArray || hasStreamingImageUrl;


	const url = getContentUrl(data, forceStreaming);
	return (
		<SArticle $isRowLayout={mobileLayout === 'row'} className={className}>
			<SLandscapeWrapper>
				<SImageSizer to={url} onClick={() => clickTracker?.(data.streamingContentId, data.title!)} data-postermissingtext={data.title}>
					{hasImage && <>
						{hasSanityImage && <CmsImageForUrl
							imageUrl={data.sanityImageWideUrl!}
							alt={`Bilde for '${data.title}'`}
							unoptimized
							width={270} />}
						{hasStreamingImageArray && <Image
							image={data.imagesLandscapeStreaming as ImageVersion[]}
							alt={`Bilde for '${data.title}'`} sizes="270px" />}
						{hasStreamingImageUrl && <ExternalImageForUrl
							imageUrl={data.imageLandscapeStreaming!}
							alt={`Bilde for '${data.title}'`} />}
					</>}
				</SImageSizer>
			</SLandscapeWrapper>
			<SHeader>
				<FilmwebLink onClick={() => clickTracker?.(data.streamingContentId, data.title!)} to={url}>
					<h1>{data.title}</h1>
					{data.productionYear && data.productionYear !== "0" && data.productionYear !== "-1" && <p>{data.productionYear}</p>}
				</FilmwebLink>
			</SHeader>
		</SArticle>
	);
}
//#endregion

//#region [Styles]
const SArticle = styled.article<SRowProps>`
	${CSSPoster}

	${props => props.$isRowLayout && css`
		@media ${theme.mq.mobile} {
			display: flex;
			width: 100%;
		}
	`}
`;

const SLandscapeWrapper = styled.div`
	position: relative;
	display: block;
`;

const SImageSizer = styled(FilmwebLink)`
	display: block;
	height: 0;
	padding-bottom: ${theme.posterAspectRatio * 100}%;
	border-radius: 4px;
	overflow: hidden;
	box-sizing: border-box;
	background: ${theme.missingPoster};
	position: relative;

	img {
		position: absolute;
		transform: translateY(-50%);
		top: 50%;
		width: 100%;
	}
`;

const SHeader = styled.header`
	line-height: 1.1;
	h1,
	p {
		font-size: 0.8rem;
	}

	p {
		font-weight: bold;
		text-align: center;
		margin: 0;
	}

	h1 {
		margin: 0.5em 0 0 0;
	}
`;
//#endregion