import { createContext, useContext } from "react";
import { ISearchResult } from "src/lib/types/search";

export type ExpScoringProfile = "Unweighted" | "CinemaPremiere";

export type ExpQueryType = "Simple" | "Full" | "Semantic";

export type ExpSearchMode = "All" | "Any";

export type ExpSearchQuestStringType = "raw" | "user";

export type QueryStringWeights = {
    f: number;
    p: number;
    e: number;
};

export type ExpState = {
    sp: ExpScoringProfile;
    qt: ExpQueryType;
    sm: ExpSearchMode;
    qst: ExpSearchQuestStringType;
    overlayData?: ISearchResult | null;
    qsw: QueryStringWeights;
    qs: string;
    pqs: string;
    setOverlayData: (data: ISearchResult | null | undefined) => void;
}
export const ExpSearchDefaultState: ExpState = { qs: "0", sp: "CinemaPremiere", qt: "Full", sm: "Any", qst: "user", qsw: { e: 1, f: 0.5, p: 1 }, setOverlayData: (data) => { }, pqs: "0" };

export const ExpSearchContext = createContext<ExpState>(ExpSearchDefaultState);

export function useExperimentalSearchContext() {
    return useContext(ExpSearchContext);
}